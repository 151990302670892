import React, { useEffect, useRef, useState } from 'react';
import StyledDropzone from './components/Dropzone';

// add typescript to project and use enum
const STATES = {
    START: "start",
    UPLOADING: "uploading"
}

const PH_EVENTS = {
    FILE_SELECTED: "notebook-file-selected",
    CONVERT_STARTED: "notebook-convert-started",
    CONVERT_SUCCESS: "notebook-converted",
    CONVERT_FAILED: "failed-to-convert-notebook",
}


// api args
const apiArgs = [
    { id: "hide-code", title: "Hide code", defaultValue: "false" },
    {
        id: "converter", title: "Converter", defaultValue: "nbconvert-webpdf", options: [
            { value: "nbconvert-webpdf", label: "WebPDF (recommended if you have plotly charts)" },
            { value: "nbconvert-pdf", label: "LaTeX (recommended if you have equations)" }
        ]
    }
]

const instructions = [
    "Select a Jupyter Notebook file",
    "Click 'Convert to PDF' button",
    "Save the PDF on your device",
    "Share your work effortlessly"
]
function NotebookToPDFConverter({ dispatchPHEvent }) {
    const [state, setState] = useState(STATES.START);
    const [disabled, setDisabled] = useState(true);
    const [file, setFile] = useState(null);
    const [selectedArgs, setSelectedArgs] = useState({});
    const [expandPDFOptions, setExpandPDFOptions] = useState(true);
    const errorMessage = useRef(null);

    useEffect(() => {
        const defaultSelectedArgs = {};
        const urlParams = new URLSearchParams(window.location.search);

        apiArgs.forEach(a => {
            const paramValue = urlParams.get(a.id);
            if (paramValue && a.options && a.options.some(option => option.value === paramValue)) {
                defaultSelectedArgs[a.id] = paramValue;
            } else if (a.id === "hide-code") {
                defaultSelectedArgs[a.id] = paramValue === "true" ? "true" : "false";
            } else {
                defaultSelectedArgs[a.id] = a.defaultValue;
            }
        });

        setSelectedArgs(defaultSelectedArgs);
    }, []);


    useEffect(() => {
        setDisabled(!file);
    }, [file])

    useEffect(() => {
        if (state === STATES.START) {
            setDisabled(!file);
        }
        else if (state === STATES.UPLOADING) {
            setDisabled(true);
        }
    }, [state])

    let convertNotebookToPDF = async () => {
        if (disabled) {
            return;
        }

        dispatchNotebookToPDFEvent()


        errorMessage.current.innerText = "";
        setState(STATES.UPLOADING);

        const formData = new FormData();
        formData.append("file", file, file.name);

        // create a method to parse selectedArgs to url with query params
        let url = `${process.env.REACT_APP_API_URL}/notebooks/convert?converter-type=${selectedArgs['converter']}&hide-code=${selectedArgs['hide-code']}`;


        fetch(url, {
            method: "POST",
            body: formData
        })
            .then((response) => {
                if (response.ok) {
                    return Promise.resolve(response.blob())
                }

                return response.json()
                    .then((json) => {
                        return Promise.reject(json)
                    })
            })
            .then(data => {
                let blob = new Blob([data], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                // window.open(url, "_blank")

                let fileName = file.name.split('.').slice(0, -1).join('.');

                const a = document.createElement('a');
                a.setAttribute('download', `${fileName}.pdf`);
                a.setAttribute('href', url);
                a.click();

                dispatchConvertionSuccessEvent()

            })
            .catch(err => {
                let errMessage = `Code: ${err.code}\nDescription : ${err.description || err.message}`;

                errorMessage.current.innerText = errMessage;
                dispatchConvertionFailedEvent(err)
            })
            .finally(() => {
                setState(STATES.START);
            })
    }

    let awsConvertNotebookToPDF = async () => {
        if (disabled) {
            return;
        }

        errorMessage.current.innerText = "";
        setState(STATES.UPLOADING);

        const formData = new FormData();
        formData.append("file", file, file.name);

        // create a method to parse selectedArgs to url with query params
        let url = 'https://s4uvfpynqvxrrk4lurjajugtdm0sycbp.lambda-url.us-east-1.on.aws/';

        fetch(url, {
            method: "POST",
            body: formData
        })
            .then(response => response.blob())
            .then(data => {
                let blob = new Blob([data], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                // window.open(url, "_blank")

                let fileName = file.name.split('.').slice(0, -1).join('.');

                const a = document.createElement('a');
                a.setAttribute('download', `${fileName}.pdf`);
                a.setAttribute('href', url);
                a.click();

            })
            .catch(err => {
                errorMessage.current.innerText = err.message;
            })
            .finally(() => {
                setState(STATES.START);
            })
    }

    let queryParamChanged = (argId, e) => {
        let value = e.target.type === 'checkbox' ? (e.target.checked ? 'true' : 'false') : e.target.value;
        let updatedArgs = { ...selectedArgs, [argId]: value };
        setSelectedArgs(updatedArgs);

        // Update URL
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(argId, value);
        const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);
    }

    // events
    let dispatchFileSelectedEvent = (error) => {
        let event = PH_EVENTS.FILE_SELECTED;
        dispatchPHEvent(event);
    }

    let dispatchNotebookToPDFEvent = () => {
        let properties = {};
        Object.keys(selectedArgs).map(key => {
            properties[key] = selectedArgs[key];
        })

        if (file) {
            properties["file_name"] = file.name
        }

        let event = PH_EVENTS.CONVERT_STARTED;
        dispatchPHEvent(event, properties);
    }


    let dispatchConvertionSuccessEvent = (error) => {
        let properties = {};
        Object.keys(selectedArgs).map(key => {
            properties[key] = selectedArgs[key];
        })

        if (file) {
            properties["file_name"] = file.name
        }

        let event = PH_EVENTS.CONVERT_SUCCESS;
        dispatchPHEvent(event, properties);
    }

    let dispatchConvertionFailedEvent = (error) => {
        let properties = {
            "error_stack": error.stack,
            "error_message": error.message
        };

        if (file) {
            properties["file_name"] = file.name
        }

        let event = PH_EVENTS.CONVERT_FAILED;
        dispatchPHEvent(event, properties);
    }

    return (<div data-testid='converter'>
        <div className='Controllers'>
            <div className='FileSelector'>
                <StyledDropzone
                    onDrop={files => {
                        let file = files[0];

                        setFile(file);
                        dispatchFileSelectedEvent();
                    }}
                    onDropRejected={() => {
                        alert("File type is not allowed. You can only upload .ipynb files")
                    }}
                />

                <div className='SelectedFile'>
                    Selected file : {file ? file.name : 'No file'}
                </div>

            </div>

            <div className={`ArgsController ${expandPDFOptions ? 'expanded' : ''}`}>
                <p>Advanced PDF options
                    <CaretIcon onClick={() => {
                        setExpandPDFOptions(!expandPDFOptions)
                    }} />
                </p>

                {
                    expandPDFOptions ?
                        apiArgs.map(a => {
                            if (a.id === "hide-code") {
                                return <div key={a.id}>
                                    <input type="checkbox" checked={selectedArgs[a.id] === 'true'} onChange={(e) => { queryParamChanged(a.id, e) }} /> <span>{a.title}</span>
                                </div>
                            } else if (a.id === "converter") {
                                return <div key={a.id}>
                                    <span>{a.title}</span>
                                    <select value={selectedArgs[a.id]} onChange={(e) => { queryParamChanged(a.id, e) }}>
                                        {a.options.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                            }
                            return null;
                        })
                        :
                        ""
                }

            </div>

        </div>

        <div className='SplitContainer'>

            <div className='Centered'>
                <div className='Actions'>
                    <div
                        className={`Button ${disabled ? "Disabled" : ""}`}
                        onClick={convertNotebookToPDF}>
                        <span>
                            {
                                state === STATES.UPLOADING ? "Converting..." : "Convert to PDF"
                            }

                        </span>
                    </div>
                    <div className='ErrorMessage' ref={errorMessage} ></div>
                </div>
                <h4>How to use Notebook Converter:</h4>
                <div className='Instructions'>
                    <ol>
                        {
                            instructions.map((section, i) => {
                                return <li key={i}>{section}</li>
                            })
                        }
                    </ol>
                    <ul>
                        <li>We do not store Notebooks or PDFs</li>
                    </ul>
                </div>
            </div>

            <div className='Centered'>
                <div className='Actions'>
                    <div className="ButtonSecondary" onClick={() => {
                        dispatchPHEvent("try-ploomber-cloud-button")
                        window.open("https://www.platform.ploomber.io/register/?utm_medium=convert&onboarding=voila", "_blank")
                    }}>
                        Convert to Dashboard
                    </div>
                </div>
                <div className="CallToAction">
                    <h4>How to deploy as a Dashboard:</h4>

                    <div className='Instructions'>
                        <ol>
                            <li key="connect">Connect to Ploomber Cloud</li>
                            <li key="new">Click on New Application</li>
                            <li key="deploy">Choose the Voila Deployment</li>
                            <li key="show">Showcase your insights</li>
                        </ol>
                        <ul>
                            <li>Elevate your data presentation for free</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default NotebookToPDFConverter;


function CaretIcon({ onClick }) {
    return (
        <img onClick={onClick} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAAo0lEQVRIie3STaqBYRTA8Z+ukis7sgwTQzuxAckejFEGUiZiG5JV3G69YeBVb74/jtnzrzN7Or9OPaRSqcgGyLAPmizfedE2EDnN5hrUxC4Q2eU7r9YLhLq3EPjBMACZoHwPgl+sPkCWqD5CTtUwfwNZoP4sUrxs9gIyfeWS8yoYP4GM8rcfVULnDtJ3/ERhtfBXAP7RjgSKNbDOp/EtJJWK6QBceqOraqxeeAAAAABJRU5ErkJggg=="></img>
    )
}
