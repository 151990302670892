import React, { useState, useEffect } from 'react';

const emojis = ['❤️', '☕', '📊', '🚀', '💡'];

function Footer() {

    const [currentEmojiIndex, setCurrentEmojiIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentEmojiIndex((prevIndex) => (prevIndex + 1) % emojis.length);
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    return (<div className='Footer'>
        <div className='Instructions'>
            <ul>
                <li>Processing time can be affected by input file size and internet connection speed</li>
            </ul>
        </div>
        <div className='CreditsAndCopyrights'>
            <span>
                Made with {emojis[currentEmojiIndex]} by <a href='https://ploomber.io/' target='_blank'>Ploomber</a>.
                Join our growing community of <a href='https://ploomber.io/community' target='_blank'>Jupyter users</a>!
            </span>
        </div>

        <SocialLinksBar />

    </div>)
}


function SocialLinksBar() {
    const socialLinks = [
        { title: "Ploomber GitHub", link: "https://github.com/ploomber/ploomber", icon: "lab la-github" },
        { title: "Ploomber Community Slack", link: "https://ploomber.io/community/", icon: "lab la-slack" },
        { title: "Ploomber Twitter", link: "https://twitter.com/ploomber", icon: "lab la-twitter" },
        { title: "Ploomber LinkedIn", link: "https://www.linkedin.com/company/ploomber", icon: "lab la-linkedin-in" },
        { title: "Ploomber Youtube Channel", link: "https://www.youtube.com/channel/UCaIS5BMlmeNQE4-Gn0xTDXQ", icon: "lab la-youtube" },
        { title: "Ploomber Newsletter", link: "https://www.getrevue.co/profile/ploomber", icon: "las la-rss" }
    ];
    return (<div className='SocialLinksBar'>
        {
            socialLinks.map((social, i) => {
                return <SocialItem key={i} link={social.link} icon={social.icon} title={social.title} />
            })
        }
    </div>)
}

function SocialItem({ title, icon, link }) {
    return (<a href={link} target='_blank' className={`SocialItem ${icon}`} title={title}></a>)
}
export default Footer;
